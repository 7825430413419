/* write your css here */

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mainSection {
  background-image: url("./assets/main-bg.jpg");
  height: 750px;
  background-size: cover; /* Ensure the image covers the entire container */
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white; /* Adjust text color for better visibility */
  scroll-behavior: smooth;
}

.fa-whatsapp  {
  color:#fff;
  background:
   linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
   radial-gradient(#25d366 60%,transparent 0);
}


/* Add media query for smaller screens */
@media (max-width: 768px) {
  .mainSection {
    height: 500px; /* Adjust height for smaller screens if needed */
    background-position: center top; /* Center the background image vertically for smaller screens */
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
